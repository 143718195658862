<template>
    <phi-page :loading="app.api.isLoading" color="#f3f3f3">

		<div slot="toolbar" class="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>{{ $t("noun.sent.plural") }}</h1>
		</div>

        <div class="posts phi-card">
            <div
                v-for="post in sent.items"
                :key="post.id"
                class="post phi-media"
                ref="item"
            >
                <router-link class="phi-media-body" :to="{name: 'thread', params:{threadId: post.thread2}}">
                    <h1 class="post-title" v-text="post.title"></h1>
                    <div class="post-preview" v-text="post.preview"></div>
                    <div class="post-date">{{ $date(post.publishDate) }}</div>
                    <div class="post-stats">{{ $t('notice.readByReadOfTotal', {read:post.read, total:post.sent}) }}</div>
                </router-link>
            </div>
            <mu-infinite-scroll v-if="!sent.isEmpty" :scroller="$el.querySelector('.phi-page-body')" :loading="sent.isLoading" @load="sent.hasNext && sent.next()" :loading-text="$t('notice.loading')" />
        </div>
    </phi-page>
</template>

<script>
import app from '../../store/app.js';

export default {
    data() {
        return {
            app,
            sent: app.api.collection(`/people/${app.user.id}/posts/sent`)
        }
    },

    mounted() {
        this.sent.fetch({totals: 1});
    },
}
</script>

<style lang="scss" scoped>

.post {
	cursor: pointer;

    .phi-media-body {
        max-width: 100%;
    }


	.post-title {
		font-weight: 400;
		font-size: 1.1em;
		margin: 0;
		padding: 0;
        word-wrap: break-word;
        min-width:0;
        width:89%;
	}

	.post-preview {
		color: #444;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
        font-size: 15px;
        flex:1;
        width:100%;
	}

	.post-date {
		font-size: 14px;
		color: #999;
	}

	.post-stats {
		color: #777;
        font-size: 14px;
	}

    .phi-card {
        word-wrap: break-word;
    }


}
</style>
